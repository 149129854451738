import Switch from '@mui/material/Switch'; // Importing Material UI Switch component for toggling options
import CustomButton from '../../../components/CustomButton'; // Importing CustomButton component
import { useEffect, useRef, useState } from 'react'; // Importing React hooks
import { Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton } from '@mui/material'; // Material UI components for dialogs and forms
import CloseIcon from '@mui/icons-material/Close'; // Close icon for dialog
import { AssessmentFile, DamageCoordinate, PinnedDamagesLabel, ReportData, SeverityLabel } from '../../../models/AI_Report'; // Importing models
import pin_green from "../../../../src/assets/images/email-report/pin.svg"; // Pin icons for damage annotations
import pin_red from "../../../../src/assets/images/email-report/pin_red.svg";
import Tooltip from '@mui/material/Tooltip'; // Importing Tooltip component
import '../SCSS/email_report_file_details.scss';  // Importing the SCSS file for styling the component

// Define props for the EmailReportImageDetails component
interface EmailReportImageDetailsProps {
    reportData: ReportData
    assessmentFiles: AssessmentFile[]; // Array of assessment files passed as a prop
    isMinorDamage: boolean; // Boolean flag to indicate if minor damages are enabled
    isPinnedDamage: boolean; // Boolean flag to indicate if pinned damages are enabled
}

// Structure for the processed image data
interface ProcessedImage {
    imageWithBbox: string; // URL for image with bounding box
    imageWithMinorDamage: string; // URL for image with minor damages
    imageWithPinDamage: string; // URL for image with pin damages
    imageWithBothDamages: string; // URL for image with both minor and pin damages
    isLoadingImage: boolean; // Boolean flag to indicate if the image is still loading
    pinnedDamages : PinnedDamagesLabel[];
}

// Main component for displaying the images with annotations
const EmailReportImageDetails = ({ reportData, assessmentFiles, isMinorDamage, isPinnedDamage }: EmailReportImageDetailsProps) => {
    const [open, setOpen] = useState(false); // State to manage dialog open/close
    const [selectedImageData, setSelectedImageData] = useState<AssessmentFile | null>(null); // State to store selected image data
    const [minorDamages, setMinorDamages] = useState(false); // State to track minor damages toggle
    const [pinDamages, setPinDamages] = useState(false); // State to track pin damages toggle
    const [pinDamagesSingleFile, setPinDamagesSingleFile] = useState(false); // State to track pinned damages for a single file
    const [minorDamagesSingleFile, setMinorDamagesSingleFile] = useState(false); // State to track minor damages for a single file
    const [processedImages, setProcessedImages] = useState<{ [key: number]: ProcessedImage }>({}); // State to store processed images
    const [isDamageOrPinDamageOnly, setIsDamageOrPinDamageOnly] = useState(true); // State to track dmage file toggle
    const [[x, y], setXY] = useState<[number, number]>([0, 0]); // State to track cursor position for magnifier
    const [[imgx, imgy], setimgXY] = useState<[number, number]>([0, 0]); // State to track cursor position within the image
    const [[imgWidth, imgHeight], setSize] = useState<[number, number]>([0, 0]); // State to store image size
    const [showMagnifier, setShowMagnifier] = useState(false); // State to control magnifier display
    const [zoomLevel, setZoomLevel] = useState(1.5); // Initial zoom level for magnifier
    const magnifierHeight = 150; // Magnifier height
    const magnifieWidth = 150; // Magnifier width

    // useEffect to initialize component state and process assessment files
    useEffect(() => {
        setMinorDamages(isMinorDamage); // Sync initial minor damages state with prop
        setPinDamages(isPinnedDamage); // Sync initial pinned damages state with prop
        defaultProcessAssessmentFiles(); // Initialize processed image data
        processAssessmentFiles(); // Process the assessment files on component mount
    }, []); // Effect runs on initial render

    // Function to initialize processed image data
    const defaultProcessAssessmentFiles = () => {
        for (let index = 0; index < assessmentFiles.length; index++) {
            setProcessedImages(prevImages => ({
                ...prevImages,
                [index]: {
                    imageWithBbox: '',
                    imageWithMinorDamage: '', // Placeholder for minor damage image
                    imageWithPinDamage: '', // Placeholder for pin damage image
                    imageWithBothDamages: '', // Placeholder for both damages image
                    isLoadingImage: true, // Indicates the image is still loading
                    pinnedDamages: []
                }
            }));
        }
    }

    // Function to process the assessment files and generate the annotated images
    const processAssessmentFiles = async () => {
        for (let index = 0; index < assessmentFiles.length; index++) {
            const element = assessmentFiles[index];

            // Initialize the image state as loading
            setProcessedImages(prevImages => ({
                ...prevImages,
                [index]: {
                    imageWithBbox: '',
                    imageWithMinorDamage: '',
                    imageWithPinDamage: '',
                    imageWithBothDamages: '',
                    isLoadingImage: true,
                    pinnedDamages:[]
                }
            }));

            // Generate annotated images for bounding box, minor damages, and pin damages
            const imageWithBbox = element.damageCoOrdinates?.length
                ? await annotatedLink(element.imageURL, element.damageCoOrdinates || [], {})
                : element.imageURL;

            element.imageWithBbox = imageWithBbox as string;

            const all_damages: DamageCoordinate[] = [
                ...(element.damageCoOrdinates ?? []),  // Combine all damage coordinates
                ...(element.minorDamageCoOrdinates ?? [])
            ] || [];

            const imageWithMinorDamage = all_damages?.length
                ? await annotatedLink(element.imageURL, all_damages || [], {})
                : element.imageWithBbox;

            const imageWithPinDamage = element.pinnedDamages?.length
                ? await annotatedLink(element.imageURL, element?.damageCoOrdinates || [], {}, element.pinnedDamages)
                : element.imageWithBbox;

            const imageWithBothDamages = (all_damages?.length || element.pinnedDamages?.length)
                ? await annotatedLink(element.imageURL, all_damages || [], {}, element.pinnedDamages || [])
                : element.imageWithBbox;

            // Update processed image data and set isLoadingImage to false
            setProcessedImages((prevImages: { [key: number]: ProcessedImage }) => ({
                ...prevImages,
                [index]: {
                    imageWithBbox: imageWithBbox as string,
                    imageWithMinorDamage: imageWithMinorDamage as string,
                    imageWithPinDamage: imageWithPinDamage as string,
                    imageWithBothDamages: imageWithBothDamages as string,
                    isLoadingImage: false, // Image processing completed,
                    pinnedDamages:element.pinnedDamages?element.pinnedDamages:[]
                }
            }));
        }
    };

    // Helper function to handle image annotation
    async function annotatedLink(
        imageLink: string,
        coordinates: any[],
        resolution: any,
        pinned_damages?: any[]
    ) {
        return new Promise(async (resolve, reject) => {
            const canvas = document.createElement("canvas"); // Create a canvas element
            const ctx: any = canvas.getContext("2d"); // Get the 2D context of the canvas
            const img = new Image(); // Create a new image element

            // Function to calculate scaling factors based on image and resolution
            const scalingFactor = (img: any, resolution: any) => {
                const dy = resolution.height ? img.height / resolution.height : 1;
                const dx = resolution.width ? img.width / resolution.width : 1;
                return { dx, dy };
            };

            // Image load event to process and draw annotations
            img.onload = function () {
                canvas.width = img.width; // Set canvas width to image width
                canvas.height = img.height; // Set canvas height to image height
                ctx.drawImage(img, 0, 0); // Draw the image on the canvas
                ctx.strokeStyle = "#FF0000"; // Set stroke color for bounding boxes
                ctx.lineWidth = 3; // Set stroke width

                const { dx, dy } = scalingFactor(img, resolution); // Calculate scaling factors

                // Draw bounding boxes for damage coordinates
                coordinates.forEach((co: any) => {
                    ctx.strokeRect(
                        co.left * dx,
                        co.top * dy,
                        Math.abs(co.left - co.right) * dx,
                        Math.abs(co.top - co.bottom) * dy
                    );
                });

                // Draw pins for pinned damages, if any
                if (pinned_damages?.length) {
                    let iconsLoaded = 0;
                    const iconsToLoad = pinned_damages.length;

                    pinned_damages.forEach((icon: any, index: number) => {
                        const iconImg = new Image(); // Create a new image element for the pin icon

                        // Load and draw the pin image
                        iconImg.onload = () => {
                            iconsLoaded++;

                            let scaledWidth = 60; // Set default pin width
                            let scaledHeight = 60; // Set default pin height
                            if (img.width > img.height) {
                                scaledWidth = 200; // Adjust for landscape images
                                scaledHeight = 200;
                            }

                            // Calculate the position of the pin based on scaling
                            const pinX = icon.axis.x * dx - 15;
                            const pinY = icon.axis.y * dy - 40;

                            // Draw the pin on the canvas
                            ctx.drawImage(iconImg, pinX, pinY, scaledWidth, scaledHeight);

                            // Draw the pin number
                            if (img.width > img.height) {
                                ctx.font = '70px Arial';
                                ctx.fillStyle = '#fff';
                                ctx.fillText((index + 1).toString(), icon.axis.x * dx + 50, icon.axis.y * dy + 85);
                            } else {
                                ctx.font = '22px Arial';
                                ctx.fillStyle = '#000';
                                ctx.fillText((index + 1).toString(), icon.axis.x * dx + 10, icon.axis.y * dy);
                            }

                            // Resolve the canvas once all icons are loaded
                            if (iconsLoaded === iconsToLoad) {
                                resolve(canvas.toDataURL());
                            }
                        };

                        // Set icon source (default to pin_green or pin_red based on chargeable status)
                        iconImg.src = icon.iconUrl || (icon.chargeable ? pin_red : pin_green);
                    });
                } else {
                    // No pinned damages, resolve the original canvas
                    resolve(canvas.toDataURL());
                }
            };

            // Handle image loading errors
            img.onerror = function () {
                console.error(`Error loading image: ${imageLink}`);
                resolve(imageLink); // Fallback to original image in case of error
            };

            // Fetch the image from the provided URL
            try {
                const res = await fetch(imageLink);
                const imageBlob = await res.blob(); // Convert response to blob
                img.src = URL.createObjectURL(imageBlob); // Set the image source
            } catch (err) {
                console.error("Error loading image from URL:", err);
                resolve(imageLink); // Fallback in case of fetch error
            }
        });
    }

    // Function to get the appropriate image based on minor damages state
    const getImage = (fileIndex: number) => {
        const fileImages = processedImages[fileIndex];

        if (minorDamages && pinDamages) {
            return fileImages?.imageWithBothDamages; // Return image with both damages
        }

        if (pinDamages && fileImages?.imageWithPinDamage) {
            return fileImages?.imageWithPinDamage;
        }

        if (minorDamages && fileImages?.imageWithMinorDamage) {
            return fileImages?.imageWithMinorDamage; // Return image with minor damages if applicable
        }

        return processedImages[fileIndex]?.imageWithBbox; // Fallback to image with bounding box
    };

    // Function to get the selected image based on user toggle (minor/pin damages)
    const getSelectedImage = (fileIndex: number) => {
        const fileImages = processedImages[fileIndex];

        if (minorDamagesSingleFile && pinDamagesSingleFile) {
            return fileImages?.imageWithBothDamages; // Return image with both damages
        }
        if (minorDamagesSingleFile) {
            return fileImages?.imageWithMinorDamage; // Return image with minor damages
        }
        if (pinDamagesSingleFile) {
            return fileImages?.imageWithPinDamage; // Return image with pin damages
        }

        return processedImages[fileIndex]?.imageWithBbox; // Fallback to image with bounding box
    };

    // Function to open the image in a dialog with enlarged view
    const enlargeImage = (report: AssessmentFile) => {
        setMinorDamagesSingleFile(minorDamages); // Sync minor damages state
        setPinDamagesSingleFile(pinDamages);
        setSelectedImageData(report); // Set the selected image data
        setOpen(true); // Open the dialog
    };

    // Function to handle dialog close and reset state
    const handleClose = () => {
        setOpen(false); // Close the dialog
        setSelectedImageData(null); // Reset selected image data
        setMinorDamagesSingleFile(false); // Reset minor damages state
        setPinDamagesSingleFile(false); // Reset single image pin damages state
        setZoomLevel(1.5); // Reset zoom level
        setShowMagnifier(false); // Disable magnifier
        setXY([0, 0]); // Reset cursor position
        setimgXY([0, 0]); // Reset cursor position within image
        setSize([0, 0]); // Reset image size
    };

    // Labels for hidden options
    const hiddenLabels = ["odometer", "license disc", "pdp scan", "vin disc", "numberplate", "vin number"];

    // Function to check if toggle options (minor/pin damages) can be shown
    const canShowToggleOption = (selectedImageData: AssessmentFile | null) => {
        if (!selectedImageData || !selectedImageData?.label) {
            return false; // Return false if no image data or label is available
        }

        // Check if the label is in the hiddenLabels array
        return !hiddenLabels.includes(selectedImageData?.label?.toLowerCase());
    };

    // List of severity labels from the SeverityLabel enum
    const severityLabels = Object.values(SeverityLabel);

    // Function to extract the severity text from the damage severity string
    const extractSeverityText = (damageWithSeverity: string | null) => {
        if (!damageWithSeverity) return '';

        // Find the severity label that matches the string
        const severityText = severityLabels.find(severity => damageWithSeverity.includes(severity));

        return severityText || ''; // Return the severity label or empty string
    };

    // Function to handle zooming in/out with the mouse wheel
    const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
        if (e.deltaY > 0) {
            setZoomLevel(Math.min(3, zoomLevel + 0.1)); // Increase zoom level, limit to 3x
        } else if (e.deltaY < 0) {
            setZoomLevel(Math.max(1, zoomLevel - 0.1)); // Decrease zoom level, limit to 1x
        }
    };

    // Function to track mouse movement for magnifier functionality
    const handleMouseMove = (e: React.WheelEvent<HTMLDivElement>) => {
        const elem = e.currentTarget;
        const { top, left } = elem.getBoundingClientRect();

        // Calculate the cursor position relative to the image
        const x = e.pageX - left - window.pageXOffset;
        const y = e.pageY - top - window.pageYOffset;
        setXY([x, y]); // Update cursor position
    };

    // Function to handle mouse entering the image (start magnifier)
    const handleMouseEnter = (e: React.MouseEvent<HTMLImageElement>) => {
        const elem = e.currentTarget;
        const { width, height } = elem.getBoundingClientRect();
        setSize([width, height]); // Set the size of the image
        setShowMagnifier(true); // Enable magnifier
    };

    // Function to track mouse movement inside the image for magnifier
    const handleMouseMoveInImg = (e: React.MouseEvent<HTMLImageElement>) => {
        const elem = e.currentTarget;
        const { top, left } = elem.getBoundingClientRect();

        // Calculate the cursor position within the image
        const imgx = e.pageX - left - window.pageXOffset;
        const imgy = e.pageY - top - window.pageYOffset;
        setimgXY([imgx, imgy]); // Update position for magnifier
    };

    // Function to handle mouse leaving the image (hide magnifier)
    const handleMouseLeave = () => {
        setShowMagnifier(false); // Disable magnifier
    };

    return (
        <>
            {/* Main container for assessment images */}
            <div className="image_details_container">
                <div className="header_part"><p className="m-0 text-center">Assessment Images</p></div>

                {/* Toggle for enabling/disabling minor damages */}
                <div className="minor_damage_switch_part">
                    <FormControlLabel
                        control={
                            <Switch checked={minorDamages} onChange={(e) => setMinorDamages(e.target.checked)} />
                        }
                        label={`${minorDamages ? 'Disable' : 'Enable'} minor damages`}
                    />
                    <br />
                    <FormControlLabel
                        control={
                            <Switch checked={isDamageOrPinDamageOnly} onChange={(e) => setIsDamageOrPinDamageOnly(e.target.checked)} />
                        }
                        label='Only show images with damage'
                    />
                    <br />
                    {/* Toggle for enabling pin damages */}
                    {(reportData?.areaWisePinCounts && Object.keys(reportData.areaWisePinCounts).length > 0) &&
                        <FormControlLabel
                            control={
                                <Switch checked={pinDamages} onChange={(e) => setPinDamages(e.target.checked)} />
                            }
                            label={`${pinDamages ? 'Disable' : 'Enable'} pin damages`}
                        />
                    }
                </div>

                {/* Display assessment files with processed images */}
                <div className="image_details_container_inner">
                    {assessmentFiles?.map((report, index) => {
                        // If `isDamageOrPinDamageOnly` is true and there's no damage or pin damage, skip rendering this image
                        if (isDamageOrPinDamageOnly && (!report.damageCoOrdinates?.length && !report.pinnedDamages?.length)) {
                            return null; // Skip rendering this element
                        }
                        return (
                            <div key={index} className="image_details_card_inner">
                                <p className='m-0 card_title'>{report.label}</p> {/* Image label */}
                                <div className="image_details_card_body_inner">
                                    <div className="left_part">
                                        <div className="image_container">
                                            {processedImages[index]?.isLoadingImage ? <div className='loading_skeleton'></div> : (
                                                <>
                                                    {/* Enlarge button to view image in dialog */}
                                                    <img src="/images/email_report/zoom_button.svg" className='enlarge_icon'
                                                        onClick={() => enlargeImage(report)} />
                                                    {/* Display the processed image */}
                                                    <img src={getImage(index)} className='car_img' alt={report.label} />

                                                    {/* Display icons for claimed, reflection, repair, and replace */}
                                                    <div className="icons_container">
                                                        {report?.claimed &&
                                                            <Tooltip title="Claimed">
                                                                <img
                                                                    alt=""
                                                                    src="/images/email_report/claimed_icon.svg"
                                                                />
                                                            </Tooltip>
                                                        }
                                                        {report?.reflected &&
                                                            <Tooltip title="Reflection">
                                                                <img alt="" src="/images/email_report/reflection.svg"
                                                                />
                                                            </Tooltip>
                                                        }
                                                        {report?.condition && report?.operation &&
                                                            <>
                                                                {report?.operation == 'Repair' &&
                                                                    <Tooltip title={report?.condition + ' | ' + report?.operation}>
                                                                        <img alt=""
                                                                            src="/images/email_report/repair_icon.svg"
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                                {report?.operation == 'Replace' &&
                                                                    <Tooltip title={report?.condition + ' | ' + report?.operation}>
                                                                        <img alt=""
                                                                            src="/images/email_report/replace_icon.svg"
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        {/* Button to enlarge the image */}
                                        {!processedImages[index]?.isLoadingImage &&
                                            <div className="enlarge_button">
                                                <CustomButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="inherit"
                                                    onClick={() => enlargeImage(report)}
                                                >
                                                    Enlarge
                                                </CustomButton>
                                            </div>
                                        }
                                    </div>

                                    {/* Additional details about the image */}
                                    <div className="right_part">
                                        <div className="data_details">
                                            <div className="head_part">
                                                <p className="m-0">Damage Type</p>
                                            </div>
                                            <div className="sub_part">
                                                <p className="m-0">
                                                    {report?.damageTypes && report?.damageTypes?.length > 0
                                                        ? report.damageTypes.map((e) => e).join(", ")
                                                        : "NA"}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="data_details">
                                            <div className="head_part">
                                                <p className="m-0">Damage Severity</p>
                                            </div>
                                            <div className="sub_part">
                                                <p className="m-0">
                                                    {extractSeverityText(report?.highestDamageSeverityAndDamageType) || 'NA'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="data_details">
                                            <div className="head_part">
                                                <p className="m-0">Additional Details</p>
                                            </div>
                                            <div className="sub_part">
                                                <p className="m-0">
                                                    {report?.additionalInfo || 'NA'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="data_details">
                                            <div className="head_part">
                                                <p className="m-0">{report.pinnedDamages.length>0?"Pin Damages":""}</p>
                                            </div>
                                            <div className="sub_part">
                                            {report.pinnedDamages?.map((damage, index) => {
                                                return (
                                                <p className="m-0">
                                                    {damage.index} : {damage.data}
                                                </p>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>

            {/* Dialog for displaying enlarged image with toggle options */}
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth className='image_details_dialog_outer'>
                <DialogTitle className='image_details_dialog_header'>
                    <p className="m-0">{selectedImageData?.label}</p> {/* Image label */}
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon className='close_icon' /> {/* Close button */}
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog_content_part">
                    {canShowToggleOption(selectedImageData) &&
                        <>
                            {/* Toggle for enabling minor damages */}
                            <div className="toggle_part">
                                <FormControlLabel
                                    control={<Switch checked={minorDamagesSingleFile} onChange={(e) => setMinorDamagesSingleFile(e.target.checked)} />}
                                    label={`${minorDamagesSingleFile ? 'Disable' : 'Enable'} minor damages`}
                                />
                            </div>

                            {/* Toggle for enabling pin damages */}
                            {(reportData?.areaWisePinCounts && Object.keys(reportData.areaWisePinCounts).length > 0) &&
                                <div className="toggle_part">
                                    <FormControlLabel
                                        control={<Switch checked={pinDamagesSingleFile} onChange={(e) => setPinDamagesSingleFile(e.target.checked)} />}
                                        label={`${pinDamagesSingleFile ? 'Disable' : 'Enable'} pin damages`}
                                    />
                                </div>
                            }
                        </>
                    }

                    {/* Display the selected image with zoom and magnifier functionality */}
                    {selectedImageData && (
                        <div className='image_and_icons' onWheel={handleWheel}
                            onMouseMove={handleMouseMove}
                            style={{ overflow: "hidden" }}
                        >
                            {/* Main image with mouse enter, move, and leave events */}
                            <img
                                src={getSelectedImage(assessmentFiles.indexOf(selectedImageData))}
                                alt={selectedImageData.label}
                                className='selected_img'
                                onMouseEnter={handleMouseEnter}
                                onMouseMove={handleMouseMoveInImg}
                                onMouseLeave={handleMouseLeave}
                            />

                            {/* Magnifier view to zoom in on the image */}
                            <div
                                style={{
                                    display: showMagnifier ? "block" : "none",
                                    position: "absolute",
                                    pointerEvents: "none", // Prevent blocking events
                                    height: `${magnifierHeight}px`, // Magnifier size
                                    width: `${magnifieWidth}px`,
                                    top: `${y - magnifierHeight / 2}px`, // Position relative to cursor
                                    left: `${x - magnifieWidth / 2}px`,
                                    opacity: "1",
                                    border: "1px solid lightgray",
                                    backgroundColor: "white",
                                    backgroundImage: `url('${getSelectedImage(assessmentFiles.indexOf(selectedImageData))}')`, // Zoomed image
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`, // Zoom level
                                    backgroundPositionX: `${-imgx * zoomLevel + magnifieWidth / 2}px`, // Positioning the zoomed image
                                    backgroundPositionY: `${-imgy * zoomLevel + magnifierHeight / 2}px`,
                                    borderWidth: "3px",
                                    borderColor: "green",
                                    borderStyle: "solid",
                                    zIndex: 9
                                }}
                            ></div>

                            {/* Icons for claimed, reflection, repair, and replace */}
                            <div className="icons_container">
                                {selectedImageData?.claimed &&
                                    <Tooltip title="Claimed">
                                        <img
                                            alt=""
                                            src="/images/email_report/claimed_icon.svg"
                                        />
                                    </Tooltip>
                                }

                                {selectedImageData?.reflected &&
                                    <Tooltip title="Reflection">
                                        <img alt="" src="/images/email_report/reflection.svg"
                                        />
                                    </Tooltip>
                                }

                                {selectedImageData?.condition && selectedImageData?.operation &&
                                    <>
                                        {selectedImageData?.operation == 'Repair' &&
                                            <Tooltip title={selectedImageData?.condition + ' | ' + selectedImageData?.operation}>
                                                <img alt=""
                                                    src="/images/email_report/repair_icon.svg"
                                                />
                                            </Tooltip>
                                        }
                                        {selectedImageData?.operation == 'Replace' &&
                                            <Tooltip title={selectedImageData?.condition + ' | ' + selectedImageData?.operation}>
                                                <img alt=""
                                                    src="/images/email_report/replace_icon.svg"
                                                />
                                            </Tooltip>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EmailReportImageDetails;
